import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import useStyles from './Greeting.styles';
import { Box } from '@material-ui/core';
import useBreakpoints from 'src/hooks/useBreakpoints';

function Greeting({ title }) {
  const { smBelow } = useBreakpoints();
  const classes = useStyles();
  const current = new Date();
  const getDayToday = () => {
    let day;
    switch (current.getDay()) {
      case 0:
        day = 'Sunday';
        break;
      case 1:
        day = 'Monday';
        break;
      case 2:
        day = 'Tuesday';
        break;
      case 3:
        day = 'Wednesday';
        break;
      case 4:
        day = 'Thursday';
        break;
      case 5:
        day = 'Friday';
        break;
      case 6:
        day = 'Saturday';
        break;
    }
    return day;
  };

  return (
    <Box pb={3} display="flex" width="100%">
      <Box display="flex" flexDirection="column" alignItems="flex-start" pb={2}>
        <Typography variant="h1" align="left" className={classes.mainTitle}>
          Welcome back, {title}
        </Typography>
        <Typography variant="h6" align="left" className={classes.subTitle}>
          Here’s what ‘s going on with business app.
        </Typography>
      </Box>

      {!smBelow && (
        <>
          <div style={{ flexGrow: 1 }} />

          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Typography
              variant="h2"
              style={{ color: '#808191', paddingBottom: 5 }}
            >
              {getDayToday()}
            </Typography>
            <Typography variant="h5">
              {current.getDate()}{' '}
              {current.toLocaleString('default', {
                month: 'long',
              })}{' '}
              {current.getFullYear()}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
}
export default Greeting;
