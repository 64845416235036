import axios from './customAxios';
const BASE_API = `${process.env.REACT_APP_UTILS_URL}`;

const createContactSupport = async (data = {}) => {
  const rs = await axios.post(`${BASE_API}/contactsupport`, data);
  return rs;
};

const utilsSvc = { createContactSupport };

export default utilsSvc;
