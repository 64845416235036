import React from 'react';
import {
  ThemeProvider,
  createTheme,
  darken,
  lighten,
  useTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles';

const AppTheme = ({ children }) => {
  const theme = useTheme();

  const selectedTheme = () => {
    const nextTheme = createTheme({
      overrides: {
        MuiButton: {
          root: {
            borderRadius: '5px',
          },
        },
        MuiButtonBase: {
          root: {
            // borderRadius: '5px',
          },
        },
        MuiOutlinedInput: {
          root: {
            borderRadius: '8px',
            backgroundColor: '#F7F7F7',
            '&.Mui-error': {
              backgroundColor: '#FFEBE6',
            },
          },
        },
        // MuiFormControl: {
        //   root: {
        //     marginTop: 16,
        //     marginBottom: 16,
        //   },
        // },
      },
      typography: {
        fontFamily: 'Inter, sans-serif',
        h1: {
          fontSize: 26,
          fontWeight: 500,
          [theme.breakpoints.down('lg')]: {
            fontSize: 22,
          },
          [theme.breakpoints.down('md')]: {
            fontSize: 20,
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: 24,
          },
        },
        h2: {
          fontSize: 24,
          fontWeight: 500,
          [theme.breakpoints.down('lg')]: {
            fontSize: 18,
          },
          [theme.breakpoints.down('md')]: {
            fontSize: 16,
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: 22,
          },
        },
        h3: {
          fontSize: 18,
          fontWeight: 500,
          [theme.breakpoints.down('lg')]: {
            fontSize: 16,
          },
          [theme.breakpoints.down('md')]: {
            fontSize: 16,
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: 18,
          },
        },
        h4: {
          fontSize: 16,
          fontWeight: 600,
          [theme.breakpoints.down('lg')]: {
            fontSize: 14,
          },
          [theme.breakpoints.down('md')]: {
            fontSize: 14,
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: 16,
          },
        },
        h5: {
          fontSize: 14,
          fontWeight: 600,
          [theme.breakpoints.down('lg')]: {
            fontSize: 12,
          },
          [theme.breakpoints.down('md')]: {
            fontSize: 12,
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: 14,
          },
        },
        h6: {
          fontSize: 12,
          fontWeight: 600,
          [theme.breakpoints.down('lg')]: {
            fontSize: 10,
          },
          [theme.breakpoints.down('md')]: {
            fontSize: 10,
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: 10,
          },
        },
        body1: {
          fontSize: 14,
          fontWeight: 400,
          [theme.breakpoints.down('lg')]: {
            fontSize: 14,
          },
          [theme.breakpoints.down('md')]: {
            fontSize: 14,
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: 14,
          },
        },
        body2: {
          fontSize: 12,
          fontWeight: 600,
          [theme.breakpoints.down('lg')]: {
            fontSize: 12,
          },
          [theme.breakpoints.down('md')]: {
            fontSize: 12,
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: 12,
          },
        },
      },
      palette: {
        primary: {
          main: '#A21D23',
        },
        secondary: {
          main: '#F0F3F6',
        },
        success: {
          main: '#66CB9F',
        },
        warning: {
          main: '#FFCE73',
        },
        info: {
          main: '#F7936F',
        },
        text: {
          secondary: '#808191',
        },
      },
    });
    return responsiveFontSizes(nextTheme);
  };

  return <ThemeProvider theme={selectedTheme()}>{children}</ThemeProvider>;
};

export default AppTheme;
