import { useContext, useState, createContext } from 'react';
import { APIRequest } from 'src/utils/api';
import { Config } from 'src/config';

const contactSupportContext = createContext({
  error: '',
  loading: false,
  submitting: false,
  SubmitContactSupportRequest: () => Promise.resolve({}),
});

export function useProvideContactSupportTable() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const SubmitContactSupportRequest = async (request = {}) => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setLoading,
      path: `${Config.utilsURL}/contactsupport/`,
      method: 'post',
      body: request,
    });
    return res;
  };

  return {
    error,
    loading,
    SubmitContactSupportRequest,
  };
}

export const useContactSupportContext = () => useContext(contactSupportContext);

export function ProvideContactSupportTable({ children }) {
  const contactsupport = useProvideContactSupportTable();
  return (
    <contactSupportContext.Provider value={contactsupport}>
      {children}
    </contactSupportContext.Provider>
  );
}
