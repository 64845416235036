import React, { useState, useEffect, useRef } from 'react';
import { Typography } from '@material-ui/core';

import ContentLayout from 'src/components/ContentLayout';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import ContactSupportForm from 'src/components/ContactSupport';

const ContactSupport = () => {
  return (
    <ContentLayout
      header={
        <>
          <Typography variant="h2">Contact Support</Typography>
          <BreadcrumbsNav pathList={[]} />
        </>
      }
    >
      <ContactSupportForm />
    </ContentLayout>
  );
};

export default ContactSupport;
