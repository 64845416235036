import { useContext, useState, createContext } from 'react';
import { APIRequest } from 'src/utils/api';
import { Config } from 'src/config';

const sysInfoUserContext = createContext({
  error: '',
  loading: false,
  submitting: false,
  ListSysInfoUser: () => Promise.resolve({}),
  UpdateSysInfoUser: () => Promise.resolve({}),
  ChangeUserMobileNumber: () => Promise.resolve({}),
  ChangeUserEmail: () => Promise.resolve({}),
  ChangeUserPassword: () => Promise.resolve({}),
});

export function useProvideSysInfoUserTable() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [sysInfoUser, setSysInfoUser] = useState({});

  const ListSysInfoUser = async (id) => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setLoading,
      path: `${Config.apiURL}/usercontact/sysinfousers/${id}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setSysInfoUser(res.body.users);
    }
    return res;
  };

  const UpdateSysInfoUser = async (id, info = {}) => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setSubmitting,
      path: `${Config.apiURL}/usercontact/sysinfousers/${id}`,
      method: 'put',
      body: info,
    });
    return res;
  };

  const ChangeUserMobileNumber = async (id, mobile = {}) => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setSubmitting,
      path: `${Config.userURL}/sysinfousers/contactwithotp/${id}`,
      method: 'put',
      body: mobile,
    });
    return res;
  };

  const ChangeUserEmail = async (id, email = {}) => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setSubmitting,
      path: `${Config.userURL}/sysinfousers/emailwithotp/${id}`,
      method: 'put',
      body: email,
    });
    return res;
  };

  const ChangeUserPassword = async (id, passwords = {}) => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setSubmitting,
      path: `${Config.userURL}/sysinfousers/password/${id}`,
      method: 'put',
      body: passwords,
    });
    return res;
  };

  return {
    error,
    loading,
    submitting,
    sysInfoUser,
    ListSysInfoUser,
    UpdateSysInfoUser,
    ChangeUserMobileNumber,
    ChangeUserEmail,
    ChangeUserPassword,
  };
}

export const useCapTableContext = () => useContext(sysInfoUserContext);

export function ProvideSysInfoUserTable({ children }) {
  const sysinfouser = useProvideSysInfoUserTable();
  return (
    <sysInfoUserContext.Provider value={sysinfouser}>
      {children}
    </sysInfoUserContext.Provider>
  );
}
