import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    companyselect: {
      '& .MuiSelect-select': {
        backgroundColor: '#FFFFFF',
      },
    },
    companyTitle: {
      color: '#B4B6CE',
      fontWeight: 600,
      padding: 15,
    },
    companyItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    addNewCompany: {
      color: '#172B4D',
      fontWeight: 600,
      padding: 15,
    },
    companyBox: {
      border: '1px solid #ccc',
      borderRadius: '10px',
      padding: 5,
      paddingLeft: 30,
      paddingRight: 30,
      backgroundColor: '#FFFFFF',
    },
    containerRoot: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  }),
);
